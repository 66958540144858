<template>
  <d-view-card
    ref="view"
    :filters="filters"
    :pagination="pagination"
    :filter-transformer="clearFilters"
    :fetch-function="
      paymentRequisitesController.getPaymentRequisitesWithFilters
    "
    :remove-function="paymentRequisitesController.removePaymentRequisite"
    remove-confirm-title="Are you sure you want to delete this payment requisite(s)"
    remove-success-title="Payment requisite(s) successfully removed"
  >
    <template
      #default="{
        loading,
        find,
        selectedData,
        removeHandler,
        data,
        filtersEntity,
      }"
    >
      <div class="col row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row q-col-gutter-sm">
            <q-input
              label="Payload"
              v-model="filtersEntity.payload"
              filled
              dense
              autogrow
              type="textarea"
              class="col-md col-6"
            />
            <q-input
              label="Agent ID"
              type="number"
              dense
              filled
              v-model.number="filtersEntity.agentId"
              class="col-md col-6"
            />
            <d-select-filtered
              multiple
              class="col-md col-6"
              label="Refill method type"
              clearable
              filled
              dense
              emit-value
              map-options
              option-value="id"
              option-label="name"
              :options="refillMethodsDictionary"
              :disable="loading"
              v-model="filtersEntity.refillMethodTypeId"
            />
            <q-input
              label="Name"
              dense
              filled
              v-model="filtersEntity.name"
              class="col-md col-6"
            />
            <d-select-filtered
              multiple
              class="col-md col-6"
              label="Currency"
              clearable
              filled
              dense
              emit-value
              map-options
              option-value="id"
              option-label="name"
              :options="currencyDictionary"
              :disable="loading"
              v-model="filtersEntity.currencyId"
            />
            <q-select
              class="col-md col-6"
              label="Status"
              clearable
              dense
              filled
              emit-value
              map-options
              :options="statuses"
              :disable="loading"
              v-model="filtersEntity.active"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              class="apply-btn"
              color="primary"
              label="Apply"
              @click="find"
            />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-payment-requisites
            :items="data"
            :disable-remove-btn="!selectedData.length"
            :selected.sync="$refs.view && $refs.view.$data.selectedData"
            :grid="$q.screen.lt.md"
            @on-remove="removeHandler"
            @on-toggle-status="updateStatus"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>
<script>
import { DPaymentRequisites } from '@/features/payment-requisistes-table';
import { DSelectFiltered } from '@/features/select-filtered';
import DViewCard from '@/layouts/view-card';
import {
  paymentRequisitesController as apiController,
  currencyController,
  refillMethodController,
} from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { notify, objectUtils } from '@/shared/utils';
import { authUtils } from '@/shared/utils';

export default {
  components: { DSelectFiltered, DViewCard, DPaymentRequisites },
  data: () => ({
    filters: {
      currencyId: [],
      agentId: null,
      refillMethodTypeId: null,
      payload: '',
      name: '',
      active: null,
    },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: false,
    },
    currencyDictionary: [],
    refillMethodsDictionary: [],
    selectedItems: [],
    statuses: [
      { value: 0, label: 'Not active' },
      { value: 1, label: 'Active' },
    ],
    paymentRequisitesController: apiController(authUtils.getRoleByHierarchy()),
  }),

  async mounted() {
    try {
      this.$refs.view.setLoading(true);
      const primaryRole = authUtils.getRoleByHierarchy();
      const currencyCall =
        primaryRole === ROLES.ROLE_ADMIN
          ? currencyController(primaryRole).getCurrency
          : currencyController(primaryRole).getCurrencyForPaymentRequisites;

      const [currencyResponse, refillMethodsResponse] = await Promise.all([
        currencyCall(),
        refillMethodController(primaryRole).getRefillMethods(),
      ]);

      this.refillMethodsDictionary = refillMethodsResponse.data;
      this.currencyDictionary = currencyResponse.data.map((item) => ({
        ...item,
        name: `${item.name} (${item.code})`,
      }));
    } catch (e) {
      notify.error('Dictionary loading error');
    } finally {
      this.$refs.view.setLoading(false);
    }
  },

  methods: {
    async updateStatus({ active, users, agentId, ...data }) {
      try {
        this.$refs.view.setLoading(true);
        await this.paymentRequisitesController.updatePaymentRequisite({
          ...data,
          ...(agentId && {
            agent: {
              id: agentId,
            },
          }),
          active: !active,
          users: users?.map(({ id }) => ({ id })),
        });
        await this.$refs.view.find();
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.$refs.view.setLoading(false);
      }
    },

    clearFilters(filters) {
      let clearedFilters = objectUtils.removeEmptyValuesRecursively(filters);

      if (filters.active === 0) {
        if (!clearedFilters) {
          clearedFilters = {};
        }
        clearedFilters.active = filters.active;
      }

      return clearedFilters;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/quasar.variables';

.apply-btn {
  width: 100%;
  @media (min-width: $breakpoint-sm-max) {
    width: auto;
  }
}
</style>
