<template>
  <fragment>
    <q-toggle
      v-if="$q.screen.gt.sm || !isDisable"
      :disable="isDisable"
      :value="value"
      checked-icon="mdi-check"
      color="green"
      unchecked-icon="mdi-close"
      @input="$emit('input', $event)"
    />
    <q-chip
      v-else
      :dense="$q.screen.lt.md"
      :label="value ? 'Active' : 'Inactive'"
      :color="value ? 'green-6' : 'red-6'"
      text-color="white"
    />
  </fragment>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({}),
};
</script>

<style scoped></style>
