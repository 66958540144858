var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-virtual-table',{staticClass:"payment-requisistes-table",attrs:{"grid":_vm.grid,"columns":_vm.columns,"items":_vm.items,"selection":_vm.isCanPaymentRequisitesActions ? 'multiple' : 'none',"with-action-btns":"","virtual-scroll":false,"withPagination":"","selected":_vm.selected},on:{"update:selected":function($event){return _vm.$emit('update:selected', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('q-space'),(!!_vm.permissions && !!_vm.permissions.allowCreateRequisites)?_c('q-btn',{class:_vm.btnClass(true),attrs:{"icon":"mdi-pencil-outline","color":"primary","label":"Create requisite","to":{ name: 'PaymentRequisitesCreate' }}}):_vm._e()]},proxy:true},{key:"body-cell-action-btns",fn:function(ref){
var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[(_vm.isCanEditRequisite)?_c('d-row-actions',{attrs:{"item":props,"page-name":"PaymentRequisitesEdit"}}):_vm._e(),(_vm.isCanFilterEditing)?_c('q-btn',{attrs:{"to":("edit/" + (props.row.id) + "/" + (props.row.paymentRequisiteFilterId
            ? 'filter-editor'
            : 'filter-editor-creation')),"flat":"","fab-mini":"","icon":props.row.paymentRequisiteFilterId
            ? 'mdi-filter-outline'
            : 'mdi-filter-plus-outline'}}):_vm._e()],1)]}},{key:"body-cell-payload",fn:function(ref){
            var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_vm._l((props.value),function(val,key){return [_c('div',{key:key,staticClass:"q-my-sm"},[_c('span',{staticClass:"text-weight-bold q-mr-xs inline-block vertical-middle"},[_vm._v(" "+_vm._s(key)+": ")]),_c('span',{staticClass:"inline-block vertical-middle"},[_vm._v(" "+_vm._s(val)+" ")])])]})],2)]}},{key:"body-cell-created-at",fn:function(ref){
            var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.formatDate(props.row.createdAt))+" ")])]}},{key:"body-cell-updated-at",fn:function(ref){
            var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.formatDate(props.row.updatedAt))+" ")])]}},{key:"body-cell-refill-method-type",fn:function(ref){
            var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_c('div',{staticClass:"flex justify-end"},_vm._l((props.row.refillMethodTypes),function(ref){
            var id = ref.id;
            var name = ref.name;
return _c('q-chip',{key:id,style:({
            color: _vm.hashStringToColor(name),
          }),attrs:{"square":"","outline":"","size":"sm"}},[_vm._v(" "+_vm._s(name)+" ")])}),1)])]}},{key:"body-cell-active",fn:function(ref){
          var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_c('d-status-toggle',{attrs:{"is-disable":!_vm.permissions || !_vm.permissions.allowSwitchRequisite,"value":props.row.active},on:{"input":function($event){return _vm.$emit('on-toggle-status', props.row)}}})],1)]}},{key:"body-cell-users",fn:function(ref){
          var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_c('div',{staticClass:"flex"},_vm._l((props.row.users),function(user){return _c('d-user-chip',{key:user.id,attrs:{"user-id":user.id,"label":user.username,"status":user.active}})}),1)])]}},{key:"grid-item",fn:function(ref){
          var props = ref.props;
return [_c('d-grid-card',{attrs:{"item":props,"is-can-filters":_vm.isCanFilterEditing,"is-can-use-requisites":_vm.isCanPaymentRequisitesActions},on:{"on-toggle-status":function($event){return _vm.$emit('on-toggle-status', $event)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }