<template>
  <d-virtual-table
    :grid="grid"
    :columns="columns"
    :items="items"
    :selection="isCanPaymentRequisitesActions ? 'multiple' : 'none'"
    with-action-btns
    :virtual-scroll="false"
    withPagination
    :selected="selected"
    class="payment-requisistes-table"
    @update:selected="$emit('update:selected', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        v-if="!!permissions && !!permissions.allowCreateRequisites"
        :class="btnClass(true)"
        icon="mdi-pencil-outline"
        color="primary"
        label="Create requisite"
        :to="{ name: 'PaymentRequisitesCreate' }"
      />
    </template>
    <template v-slot:body-cell-action-btns="{ props }">
      <q-td :props="props">
        <d-row-actions
          v-if="isCanEditRequisite"
          :item="props"
          page-name="PaymentRequisitesEdit"
        />
        <q-btn
          :to="`edit/${props.row.id}/${
            props.row.paymentRequisiteFilterId
              ? 'filter-editor'
              : 'filter-editor-creation'
          }`"
          v-if="isCanFilterEditing"
          flat
          fab-mini
          :icon="
            props.row.paymentRequisiteFilterId
              ? 'mdi-filter-outline'
              : 'mdi-filter-plus-outline'
          "
        />
      </q-td>
    </template>
    <template v-slot:body-cell-payload="{ props }">
      <q-td :props="props">
        <template v-for="(val, key) in props.value">
          <div :key="key" class="q-my-sm">
            <span class="text-weight-bold q-mr-xs inline-block vertical-middle">
              {{ key }}:
            </span>

            <span class="inline-block vertical-middle">
              {{ val }}
            </span>
          </div>
        </template>
      </q-td>
    </template>

    <template v-slot:body-cell-created-at="{ props }">
      <q-td :props="props">
        {{ formatDate(props.row.createdAt) }}
      </q-td>
    </template>

    <template v-slot:body-cell-updated-at="{ props }">
      <q-td :props="props">
        {{ formatDate(props.row.updatedAt) }}
      </q-td>
    </template>

    <template v-slot:body-cell-refill-method-type="{ props }">
      <q-td :props="props">
        <div class="flex justify-end">
          <q-chip
            v-for="{ id, name } in props.row.refillMethodTypes"
            :key="id"
            :style="{
              color: hashStringToColor(name),
            }"
            square
            outline
            size="sm"
          >
            {{ name }}
          </q-chip>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-active="{ props }">
      <q-td :props="props">
        <d-status-toggle
          :is-disable="!permissions || !permissions.allowSwitchRequisite"
          :value="props.row.active"
          @input="$emit('on-toggle-status', props.row)"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-users="{ props }">
      <q-td :props="props">
        <div class="flex">
          <d-user-chip
            v-for="user in props.row.users"
            :key="user.id"
            :user-id="user.id"
            :label="user.username"
            :status="user.active"
          />
        </div>
      </q-td>
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-card
        :item="props"
        :is-can-filters="isCanFilterEditing"
        :is-can-use-requisites="isCanPaymentRequisitesActions"
        @on-toggle-status="$emit('on-toggle-status', $event)"
      />
    </template>
  </d-virtual-table>
</template>

<script>
import { tableColumns as columns } from './config';
import { DGridCard, DStatusToggle, DUserChip } from './features';
import { DRowActions } from '@/features/row-actions';
import { ROLES } from '@/shared/constants';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';
import { authUtils } from '@/shared/utils';
import { hashStringToColor } from '@/shared/utils/hashStringToColor';
import { ComputedPermissions } from '@/shared/utils/permissionsHelper';
import { mapGetters } from 'vuex';

export default {
  components: {
    DVirtualTable,
    DGridCard,
    DStatusToggle,
    DRowActions,
    DUserChip,
  },
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disableRemoveBtn: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Array,
      default: () => undefined,
    },
  },
  mixins: [withActionTableClasses],
  data: () => ({
    columns: authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR])
      ? columns
      : columns.filter((col) => col.field !== 'agentId'),
  }),
  computed: {
    ...mapGetters({
      userPermissions: 'GET_USER_PERMISSIONS',
    }),
    isCanFilterEditing: () => authUtils.checkRoles([ROLES.ROLE_ADMIN_FILTER]),
    isCanPaymentRequisitesActions: () =>
      authUtils.checkRoles([
        ROLES.ROLE_AGENT,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
      ]),
    permissions: ({ $store }) => $store.getters.GET_USER_PERMISSIONS,
    isCanEditRequisite: ({ $store }) =>
      ComputedPermissions.canEditRequisite($store.getters.GET_USER_PERMISSIONS),
  },
  methods: {
    hashStringToColor(value) {
      return hashStringToColor(value);
    },
    formatDate(value) {
      if (!value) {
        return '';
      }

      return new Date(value).toLocaleString('en-EN', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 53em !important;
}

.payment-requisistes-table {
  ::v-deep {
    .q-table__middle {
      &.scroll {
        max-height: calc(100vh - 380px);
      }

      & > .q-table > thead {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: #fff;
      }
    }
  }
}
</style>
