<template>
  <d-grid-card-wrapper :is-selected="item.selected">
    <q-card-section>
      <div class="grid-item__row">
        <div class="grid-item__title_inline">
          <q-checkbox
            v-if="isCanUseRequisites"
            class="q-mr-sm"
            dense
            v-model="item.selected"
          />
          <span>
            {{ item.row.name }}
          </span>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section>
      <div class="grid-item__row">
        <div class="grid-item__title">Operators</div>
        <div class="grid-item__value">
          <template v-if="item.row.users">
            <template v-for="user in item.row.users">
              <d-user-chip
                :key="user.id"
                :user-id="user.id"
                :label="user.username"
                :status="user.active"
              />
            </template>
          </template>
          <span v-else>none</span>
        </div>
      </div>
      <template v-for="prop in simpleProps">
        <div :key="prop.name" class="grid-item__row">
          <div class="grid-item__title">
            {{ prop.label }}
          </div>
          <div class="grid-item__value">
            {{ prop.value }}
          </div>
        </div>
      </template>
      <div class="grid-item__row">
        <div class="grid-item__title_inline">
          Status
          <d-status-toggle
            :is-disable="!permissions || !permissions.allowSwitchRequisite"
            :value="item.row.active"
            @input="$emit('on-toggle-status', item.row)"
          />
        </div>
      </div>
      <div class="q-mt-md">
        <q-btn-group spread>
          <d-row-actions
            v-if="isCanEditRequisite"
            :item="item"
            page-name="PaymentRequisitesEdit"
          />
          <q-btn
            :to="`edit/${item.row.id}/${
              item.row.paymentRequisiteFilterId
                ? 'filter-editor'
                : 'filter-editor-creation'
            }`"
            v-if="isCanFilters"
            :icon="
              item.row.paymentRequisiteFilterId
                ? 'mdi-filter-outline'
                : 'mdi-filter-plus-outline'
            "
          />
        </q-btn-group>
      </div>
    </q-card-section>
  </d-grid-card-wrapper>
</template>

<script>
import { default as DStatusToggle } from './status-toggle.vue';
import { default as DUserChip } from './user-chip.vue';
import { DGridCardWrapper } from '@/features/grid-card-wrapper';
import { DRowActions } from '@/features/row-actions';
import {mapGetters} from "vuex";
import {ComputedPermissions} from "@/shared/utils/permissionsHelper";

export default {
  components: { DStatusToggle, DGridCardWrapper, DRowActions, DUserChip },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isCanUseRequisites: {
      type: Boolean,
      default: () => false,
    },
    isCanFilters: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      userPermissions: 'GET_USER_PERMISSIONS',
    }),
    simpleProps: ({ item }) =>
      item.cols.filter(
        (el) =>
          el.name !== 'payload' &&
          el.name !== 'users' &&
          el.name !== 'active' &&
          el.name !== 'action-btns' &&
          el.name !== 'name'
      ),

    permissions: ({ $store }) =>
        $store.getters.GET_USER_PERMISSIONS,
    isCanEditRequisite: ({$store}) => ComputedPermissions.canEditRequisite($store.getters.GET_USER_PERMISSIONS),
  },
};
</script>
