<template>
  <fragment>
    <q-btn
      flat
      fab-mini
      icon="mdi-pencil"
      :to="{
        name: pageName,
        params: { id: item.row.id },
      }"
    />
  </fragment>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
  },
};
</script>
