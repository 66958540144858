export const tableColumns = [
  {
    name: 'id',
    field: 'id',
    label: 'ID',
    align: 'left',
  },
  {
    name: 'currency',
    field: ({ currency }) =>
      currency ? `${currency.name} (${currency.code})` : '',
    label: 'Currency',
  },
  {
    name: 'users',
    field: 'users',
    label: 'Operators',
    align: 'left',
  },
  {
    name: 'agent-id',
    field: 'agentId',
    label: 'Agent Id',
  },
  {
    name: 'name',
    field: 'name',
    label: 'Name',
  },
  {
    name: 'weight',
    field: 'weight',
    label: 'Weight',
  },
  {
    name: 'payload',
    field: 'payload',
    label: 'Payload',
  },
  {
    name: 'refill-method-type',
    label: 'Refill method type',
  },
  {
    name: 'created-at',
    label: 'Created at',
  },
  {
    name: 'updated-at',
    label: 'Updated at',
  },
  {
    name: 'active',
    field: 'active',
    label: 'Status',
  },
];
