<template>
  <d-profile-chip
    :to="toConfig"
    :label="label"
    :status="status"
    is-dense
    is-with-badge
  />
</template>

<script>
import { DProfileChip } from '@/features/profile-chip';
import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  components: { DProfileChip },
  props: {
    label: {
      type: String,
      required: true,
    },
    userId: {
      type: [String, Number],
      required: true,
    },
    status: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    toConfig() {
      const isCan = authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
      ]);

      if (!isCan) return undefined;
      return { name: 'UsersEdit', params: { id: this.userId } };
    },
  },
};
</script>
